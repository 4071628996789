import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NotificationService } from '../../../notification.service';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ApiService } from '../../../api.service';
import { Router } from '@angular/router'

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  notiCountVal:number=0;
  subscription: Subscription;
  subscriptionCount: Subscription;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  showPopup = false;
  notifyArr: any = [];
  spinNotification:boolean=false;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Bunk Settings' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private notiservice: NotificationService,
    private breakpointService: NbMediaBreakpointsService,
    private api: ApiService, private router: Router,) {

    this.subscription = this.notiservice.notify$.subscribe(res => {
      if (res == "open") {
        this.toggleNotification(true)
      }
      else if (res == "close") {
        this.toggleNotification(false)
      }

    })

    this.subscriptionCount = this.notiservice.notifyCount$.subscribe(res => {
      this.notiCountVal=res;
    })

    menuService.onItemClick().subscribe(() => {
      if (window.innerWidth <= 575) {
        this.toggleSidebar();
      }
    });
  }

  notificationClick(obj, e) {

    console.log(e)
    if (obj.webActionPage) {
      this.router.navigate([obj.webActionPage]);
    }

    var data = { "notificationId": obj.notificationId, "updateType": ["readStatus"] }
    this.api.update("notifications/update", data).subscribe(res => {
      console.log(res)
    })
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = { name: sessionStorage.getItem('ownername'), picture: 'assets/images/avatar.png' });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  toNotify() {
    this.router.navigate(['/pages/notify']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.notiservice.send("true")

    console.log(window.innerWidth);
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  toggleNotification(status: boolean) {
    this.showPopup = status;
    if (this.showPopup) {
      this.spinNotification=true;
      this.api.gets('notifications/readactive/1').subscribe(res => {
        this.notifyArr = res;
        this.spinNotification=false;
      })
    }
    // console.log("",status)
    // if(status=="eva eva-bell-outline"){
    //   console.log("triggered",status)
    //   this.showPopup=!this.showPopup;
    // }
  }
}


