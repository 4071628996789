import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MethodserviceService } from '../../../../app/pages/forms/methodservice.service';
import { Subscription } from 'rxjs'
@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>                                  
      <nb-layout-header fixed  *ngIf="showLink">
        <ngx-header></ngx-header>       
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar fixed1" tag="menu-sidebar" responsive *ngIf="showLink">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed class="fixed footerCls">
        <ngx-footer></ngx-footer>
      </nb-layout-footer> 
    </nb-layout>    
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  showLink = false;
  subscription: Subscription;

  constructor(private methodservice: MethodserviceService, private router: Router, private mservice: MethodserviceService) {

  }

  ngOnInit() {
    this.subscription = this.methodservice.methodMessage$.subscribe(res => {
      this.showLink=res;
    })
    // console.log("URL", this.router.url)
    // let url = this.router.url;
    // if (url == '/pages/forms/bupr' || url == '/pages/forms/prod' || url == '/pages/forms/bust' || url == '/pages/forms/emde' || url == '/pages/forms/reve' || url == '/pages/forms/expe' || url == '/pages/forms/cred' || url == '/pages/forms/eqse') {
    //   this.showLink = false;
    // } else {
    //   this.showLink = true;
    // }

  }
}
