import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by&#169;">&#169; by <b><a href="http://eternl.in/" target="_blank">Eternl Resilient Tech. Pvt. Ltd.</a></b></span>
  `,
})
export class FooterComponent {
}
