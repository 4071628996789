import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class MethodserviceService {

  private methodSource = new Subject<boolean>();

  methodMessage$ = this.methodSource.asObservable();

  constructor() { }

  send(message: boolean) {

    this.methodSource.next(message);
  }
}
