import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { AuthlogService } from './authlog.service';
import { Router } from '@angular/router';
import { NbToastrService, NbComponentStatus } from '@nebular/theme';
import { MethodserviceService } from '../app/pages/forms/methodservice.service';
import { EncrDecrServiceService } from './encr-decr-service.service'

const apiUrl = "https://gazo.co.in/api/v1";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //Some changes
  //apiUrl  :any="https://gazo.co.in/api/v1";
  apiUrl  :any;
  constructor(private enc: EncrDecrServiceService, private http: HttpClient, private methodservice: MethodserviceService, private authlogservice: AuthlogService, private router: Router, private toastrService: NbToastrService) {
    this.http.get('https://www.gazo.in/apiConfig.json').subscribe((data:any) => {
      console.log('data', data);
     this.apiUrl = data.apiUrl;
  })
   }
  showToast(status: NbComponentStatus, message, duration) {
    this.toastrService.show(status, `${message}`, { status });
  }
  url = this.router.url;

  gets(urlpart): Observable<any[]> { console.log( this.apiUrl);
    const url = `${apiUrl}/${urlpart}`;
    //const url = `${this.apiUrl}/${urlpart}`;
    var token = sessionStorage.getItem('token');
    var headers_object = new HttpHeaders().set('Content-Type', 'application/json');
    headers_object = new HttpHeaders().set('Authorization', 'JWT ' + token);
    const httpOptions = {
      headers: headers_object
    };
    return this.http.get<any[]>(url, httpOptions)
      .pipe(
        tap(any => {
          //console.log('success', 'Successfully Get Data', true)
        }),
        catchError(this.handleError('getUser', []))
      );
  }

  get(urlpart, id: number): Observable<any> {
    const url = `${apiUrl}/${urlpart}/${id}`;
    var token = sessionStorage.getItem('token');
    var headers_object = new HttpHeaders().set('Content-Type', 'application/json');
    headers_object = new HttpHeaders().set('Authorization', 'JWT ' + token);
    const httpOptions = {
      headers: headers_object
    };
    return this.http.get<any>(url, httpOptions).pipe(
      tap(any => console.log('success', 'Successfully Get Single Data', true)),
      catchError(this.handleError<any>(`getUser id=${id}`))
    );
  }

  getObj(urlpart, id: object): Observable<any> {
    const url = `${apiUrl}/${urlpart}/${id}`;
    var token = sessionStorage.getItem('token');
    var headers_object = new HttpHeaders().set('Content-Type', 'application/json');
    headers_object = new HttpHeaders().set('Authorization', 'JWT ' + token);
    const httpOptions = {
      headers: headers_object
    };
    return this.http.get<any>(url, httpOptions).pipe(
      tap(any => console.log('success', 'Successfully Get Single Data', true)),
      catchError(this.handleError<any>(`getUser id=${id}`))
    );
  }

  add(urlpart, any): Observable<any> {
    var token = sessionStorage.getItem('token');
    var headers_object = new HttpHeaders().set('Content-Type', 'application/json');
    headers_object = new HttpHeaders().set('Authorization', 'JWT ' + token);
    const httpOptions = {
      headers: headers_object
    };
    const url = `${this.apiUrl}/${urlpart}`;
    return this.http.post<any>(url, any, httpOptions).pipe(
      tap((insert: any) => {

        if (this.url == "/pages/forms/BUPR" || this.url == "/pages/forms/PROD" || this.url == "/pages/forms/BUST" || this.url == "/pages/forms/EMDE" || this.url == "/pages/forms/REVE" || this.url == "/pages/forms/EXPE" || this.url == "/pages/forms/CRED" || this.url == "/pages/forms/EQSE") {
          this.methodservice.send(insert.message)
        }
        else {
          if (insert.status == false) {
            this.showToast('danger', insert.message, true)
          } else {
            this.showToast('success', insert.message, true)
          }
        }

      }),
      catchError(this.handleError<any>('add'))
    );
  }
  postget(urlpart, any, report): Observable<any> { console.log( this.apiUrl);
    // let datas = this.enc.encryptUsingAES256(any)
    let datas = any;
    var token = sessionStorage.getItem('token');
    var headers_object = new HttpHeaders().set('Content-Type', 'application/json');
    headers_object = new HttpHeaders().set('Authorization', 'JWT ' + token);
    const httpOptions = {
      headers: headers_object
    };
    const url = `${this.apiUrl}/${urlpart}`;
    if (report == false) {
      return this.http.post<any>(url, datas, httpOptions).pipe(
        tap((insert: any) => {
          if (insert.status == false) {
            this.showToast('danger', insert.message, true)
          } else {
            this.showToast('success', insert.message, true)
          }
        }
        ),
        catchError(this.handleError<any>('postget'))
      );
    }
    else {
      return this.http.post<any>(url, datas, httpOptions).pipe(
        catchError(this.handleError<any>('postget'))
      );
    }
  }
  downloadPDF(urlpart, any, report): any {

    /** this.http.get(url, { headers: new HttpHeaders({
  'Authorization': 'Basic ' + encodedAuth,
  'Content-Type': 'application/octet-stream',
  }), responseType: 'blob'}) */
  let datas = any;
    var token = sessionStorage.getItem('token');
    var headers_object = new HttpHeaders().set('Content-Type', 'application/octet-stream');
    headers_object = new HttpHeaders().set('Authorization', 'JWT ' + token);
  //  headers_object = new HttpHeaders().set('Accept', 'application/octet-stream');
    const httpOptions = {
      headers: headers_object,
     responseType: 'blob' as 'json'
    //responseType: 'arraybuffer' as 'json',
    };
    const url = `${this.apiUrl}/${urlpart}`;
    return this.http.get<any>(url, httpOptions).pipe(
      tap((insert: any) => {
        if (insert.status == false) {
          this.showToast('danger', insert.message, true)
        } else {
         // this.showToast('success', insert.message, true)
        }
      }
      ),
      catchError(this.handleError<any>('postget'))
    );
}
  update(urlpart, any): Observable<any> {
    const url = `${this.apiUrl}/${urlpart}`;
    var token = sessionStorage.getItem('token');
    var headers_object = new HttpHeaders().set('Content-Type', 'application/json');
    headers_object = new HttpHeaders().set('Authorization', 'JWT ' + token);
    const httpOptions = {
      headers: headers_object
    };
    return this.http.put(url, any, httpOptions).pipe(
      tap(data => {
        if (data['message'] != undefined) {
          this.showToast('success', data['message'], true);
        }
      }),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  delete(urlpart, any): Observable<any> {
    var token = sessionStorage.getItem('token');
    var headers_object = new HttpHeaders().set('Content-Type', 'application/json');
    headers_object = new HttpHeaders().set('Authorization', 'JWT ' + token);
    const httpOptions = {
      headers: headers_object
    };
    const url = `${this.apiUrl}/${urlpart}`;
    return this.http.put<any>(url, any, httpOptions).pipe(
      tap(data => {
        if (this.url == "/pages/forms/BUPR" || this.url == "/pages/forms/PROD" || this.url == "/pages/forms/BUST" || this.url == "/pages/forms/EMDE" || this.url == "/pages/forms/REVE" || this.url == "/pages/forms/EXPE" || this.url == "/pages/forms/CRED" || this.url == "/pages/forms/EQSE") {
          this.methodservice.send(data.message)
        } else {
          this.showToast('success', data.message, true)
        }
      }),
      catchError(this.handleError<any>('delete'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.log("Error service", error);
      // TODO: send the error to remote logging infrastructure
      if (error) {
        this.showToast('danger', error.error.message ? error.error.message : error.message, true);
        //console.error(error); // log to console instead
        if (error.status == 403) {
          this.showToast('danger', error.error.message, true);
          this.authlogservice.logout();
          this.router.navigate(['/logingazo']);
        }
        // if (error.status == 500) {
        //   this.showToast('danger', "Internal Server Error", true);
        // }
        // if (error.status == 502) {
        //   this.showToast('danger', "Bad Gateway", true);
        // }
        // if (error.status == 503) {
        //   this.showToast('danger', "Service Unavailable", true);
        // }
        // if (error.status == 504) {
        //   this.showToast('danger', "Gateway Timeout", true);
        // }
        // if (error.status == 505) {
        //   this.showToast('danger', "HTTP Version Not Supported", true);
        // }
        // if (error.status == 511) {
        //   this.showToast('danger', "Network Authentication Required", true);
        // }

        // if (error.statusText=="Unknown Error") {
        //    this.router.navigate(['/error'], { queryParams: { data: error.status + "-" + error.error.message ? error.error.message : error.message } });
        // }
        if (error.status == 502) {
          //console.log("502",error)
          // this.router.navigate(['/error'], { queryParams: { data: error.status + "-" + error.error.message ? error.error.message : error.message } });
        }
        if (error.statusText=="Unknown Error" || error.status > 499 && error.status < 600) {
          this.router.navigate(['/error'], { queryParams: { data: error.status + "-" + error.error.message ? error.error.message : error.message } });
        }


      }
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
