import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalModule } from 'ngx-bootstrap/modal'

import {  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbActionsModule,  NbCardModule, NbCheckboxModule,  NbIconModule,  NbRadioModule, NbSelectModule, NbUserModule, NbAccordionModule, NbListModule, NbRouteTabsetModule,  NbSpinnerModule, NbTabsetModule, NbLayoutModule, } from '@nebular/theme';
import { ThemeModule } from '../@theme/theme.module';
import { NgxPrintModule } from 'ngx-print';
import { FormsModule as ngFormsModule, ReactiveFormsModule } from '@angular/forms';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import {NotificationService} from '../notification.service'
@NgModule({
  declarations: [],
  imports: [
        NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    // NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    // NbChatModule.forRoot({
    //   messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    // }),
    NbListModule,
    PdfViewerModule,
    NbRouteTabsetModule,
    NbTabsetModule,
    ngFormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxPrintModule,
    ThemeModule,
    CommonModule,
    NgxPaginationModule,
    NbLayoutModule,
    
    NbCardModule,
    
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    
    NbSelectModule,
    NbIconModule,
    NbSpinnerModule,
    
    NbAccordionModule,
  ],
  exports: [
    NbSidebarModule,
    NbMenuModule,
    NbDatepickerModule,
    NbDialogModule,
    // NbWindowModule,
    NbToastrModule,
    // NbChatModule,

    NbListModule,
    PdfViewerModule,
    NbAccordionModule,
    NbRouteTabsetModule,
    NbTabsetModule,
    ModalModule,
    ThemeModule,
    NgxPaginationModule,
    NbLayoutModule,
    
    NbCardModule,
    
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbSpinnerModule,
    
  ],
  providers:[]
})
export class SharedModule { }
