import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { encode } from 'punycode';
import * as shajs from 'sha.js';
import { concat } from 'rxjs';
var aesjs = require('aes-js');


@Injectable({
  providedIn: 'root'
})
export class EncrDecrServiceService {

  tokenFromUI: string = "0123456789123456";

  constructor() {
    // console.log("Constructor is running");
    // this.decryptUsingAES256(1)
  }

  getIV(size) {
    var result = [];
    var characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_+}{';
    var charactersLength = characters.length;
    for (var i = 0; i < size; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }
  getKey(size) {
    var result = [];
    var characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()_+}{';
    var charactersLength = characters.length;
    for (var i = 0; i < size; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  encryptUsingAES256(data) {
    console.log("IV", this.getIV(16))
    console.log("Key", this.getKey(32))
    // iv=16 bytes
    // key=32 byte
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse("{}60pqjvLwLc%feb");
    let _key = CryptoJS.enc.Utf8.parse("KfeI(OGD)Ma*{}sfSLsATn7h)wT__HsO");
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    });
    console.log(typeof encrypted, encrypted)
    return encrypted.toString();
  }

  decryptUsingAES256(data1) {
    let data = atob("8dnCXSrBLjAJnawI9JHzuzb9rPPpCwG18U8HY5bPaJNCuFIjPZll7CTNBNULqC9m")
    console.log("data", data.substring(0, 16))
    let _key = CryptoJS.enc.Utf8.parse('KfeI(OGD)Ma*{}sfSLsATn7h)wT__HsO')
    let _iv = data.substring(0, 16);
    console.log("IV", _iv)
    console.log("substring", data.substring(16))
    // let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    var decrypted = CryptoJS.AES.decrypt(
      data.substring(16), _key, {
      keySize: 32,
      blockSize:16,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  encrypt(value: string, secretKey): string {
    return CryptoJS.AES.encrypt(value, secretKey).toString();
  }

  decrypt(textToDecrypt: string, secretKey) {
    return CryptoJS.AES.decrypt(textToDecrypt, secretKey).toString(CryptoJS.enc.Utf8);
  }

  encryptDemo(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), "12345").toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptDemo(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, "12345");
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  setnew(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding
      });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  getnew(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  //The set method is use for encrypt the value.
  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse('GaZo@EtErNl2702%');
    // console.log('Key:'+key)
    var iv = CryptoJS.enc.Utf8.parse(CryptoJS.lib.WordArray.random(8));
    // console.log('ivran:'+CryptoJS.lib.WordArray.random(8))

    // console.log('iv:'+iv)
    // console.log('value:'+CryptoJS.enc.Utf8.parse(value.toString()))
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
        blockSize: 32,
      });
    // var encryptedivCon=CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
    // console.log(CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted.iv)))
    var encryptedivCon = iv.concat(encrypted.ciphertext);
    var encryptedHash = CryptoJS.SHA256(encryptedivCon);
    // console.log(CryptoJS.SHA256(encryptedivCon));
    // var enleng=encryptedivCon.concat(encryptedHash);
    // console.log(enleng.length);
    var encryptedHashCon = CryptoJS.enc.Base64.stringify(encryptedivCon.concat(encryptedHash));
    // console.log(encryptedHashCon);
    return encryptedHashCon;
  }

  //The get method is use for decrypt the value.
  get(keys, value) {
    var key = CryptoJS.enc.Utf8.parse('GaZo@EtErNl2702%');
    var iv = CryptoJS.enc.Utf8.parse('1111111111111111');
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.ZeroPadding
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  testData() {
    var enc = this.encryptUsingAES256("Hello, this is my new text for encr")
    console.log("Encrypted text: ", enc)
    var dec = this.decryptUsingAES256(enc)
    console.log("Decrypted text: ", dec)
    return;
    // An example 128-bit key
    var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

    // The initialization vector (must be 16 bytes)
    var iv = [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36];

    // Convert text to bytes (text must be a multiple of 16 bytes)
    var text = 'TextMustBe16Byte';
    var textBytes = aesjs.utils.utf8.toBytes(text);

    var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
    var encryptedBytes = aesCbc.encrypt(textBytes);

    // To print or store the binary data, you may convert it to hex
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    console.log(encryptedHex);
    // "104fb073f9a131f2cab49184bb864ca2"

    // When ready to decrypt the hex string, convert it back to bytes
    var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

    // The cipher-block chaining mode of operation maintains internal
    // state, so to decrypt a new instance must be instantiated.
    var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
    var decryptedBytes = aesCbc.decrypt(encryptedBytes);

    // Convert our bytes back into text
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    console.log(decryptedText);
    // "TextMustBe16Byte"
  }
  // setCBC(keys, value){

  //   var KEY = 'This is a key123';
  //   var IV = 'This is an IV456';
  //   var MODE = CryptoJS.mode.CFB;
  //   var plaintext = 'The answer is no';
  //   var input_bytes = CryptoJS.enc.Utf8.parse(plaintext);
  //   console.log(input_bytes);
  //   var key = CryptoJS.enc.Utf8.parse(KEY);
  //   var options = {iv: CryptoJS.enc.Utf8.parse(IV), asBytes: true, mode: MODE,padding:CryptoJS.pad.ZeroPadding};
  //   var encrypted = CryptoJS.AES.encrypt(input_bytes, key, options);
  //   var encrypted_hex = CryptoJS.enc.Hex.parse(encrypted.toString());
  //   console.log(encrypted_hex); // this is the value you send over the wire
  //   var output_bytes = CryptoJS.enc.Hex.parse(encrypted_hex);
  //   var output_plaintext_bytes = CryptoJS.AES.decrypt(output_bytes, key, options);
  //   var output_plaintext = CryptoJS.enc.Utf8.parse(output_plaintext_bytes.toString());
  //   console.log(output_plaintext); // result: 'The answer is no'

  //   var key = CryptoJS.enc.Hex.parse(CryptoJS.SHA1(keys).toString());
  //   var iv =  CryptoJS.enc.Hex.parse(CryptoJS.SHA1(keys).toString());
  //   console.log(CryptoJS.enc.Hex.parse(CryptoJS.SHA256(keys).toString()))
  //   var encrypted = CryptoJS.AES.encrypt(value, key,
  //   {
  //       keySize:16,
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       blockSize: 16,
  //       padding: CryptoJS.pad.Pkcs7
  //   });
  //   return btoa(encrypted.toString());
  //   // var values =  CryptoJS.enc.Utf8.parse(shajs('sha256').update({value}).digest('hex'));
  //   // console.log(values);
  //   // var key =  CryptoJS.enc.Utf8.parse(keys);
  //   // console.log(keys);
  //   // var iv =  CryptoJS.enc.Utf8.parse(keys);
  //   // var encrypted = CryptoJS.AES.encrypt(values, key, {
  //   //   keySize: 128,
  //   //     iv: iv,
  //   // });
  //   // console.log(iv.concat(encrypted.ciphertext).toString());
  //   // return CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext).toString());

  // }

  //The get method is use for decrypt the value.
  // getCBC(keys, value){
  //      var key = CryptoJS.enc.Utf8.parse(keys);
  //      var iv = CryptoJS.enc.Utf8.parse(keys);
  //      var decrypted = CryptoJS.AES.decrypt(value, key, {
  //       keySize: 128 / 16,
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //   });

  //   return decrypted.toString(CryptoJS.enc.Utf8);
  // }
}
