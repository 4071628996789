import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
// import { LoginGazoComponent } from './authmodule/login-gazo/login-gazo.component';
// import { RegisterGazoComponent } from './authmodule/register-gazo/register-gazo.component';
// import { EmailVerificationComponent } from './authmodule/email-verification/email-verification.component';
// import { DummyLoginComponent } from './dummy-login/dummy-login.component';
// import { ForgetpasswordComponent } from './authmodule/forgetpassword/forgetpassword.component';
// import { ResetpasswordComponent } from './authmodule/resetpassword/resetpassword.component';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('../app/pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: '',
    loadChildren: () => import('../app/authmodule/authmodule.module')
      .then(m => m.AuthmoduleModule),
  },


  { path: '', redirectTo: 'logingazo', pathMatch: 'full' },
  { path: '**', redirectTo: 'logingazo' },
];

const config: ExtraOptions = {
  useHash: true,

};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
