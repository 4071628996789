import { Injectable } from '@angular/core';
import{ login } from './authmodule/login-gazo/login';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthlogService {

  constructor() { }
  logout() :void {
    sessionStorage.setItem('isLoggedIn','false');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('ownername');
    sessionStorage.clear();
    }
}
