import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notifySource = new Subject<string>();
  notify$ = this.notifySource.asObservable();

  constructor() { }
  send(message: string) {
    this.notifySource.next(message);
  }

  private notifyCountSource = new Subject<number>();
  notifyCount$ = this.notifyCountSource.asObservable();

  sendCount(message: number) {
    //console.log("received to service",message)
    this.notifyCountSource.next(message);
  }


}
